import moment from "moment"
const initialState = {
  chats: [],
  contacts: [],
  userProfile: {},
  selectedUser: {},
  selectedPage: {},
  ichatContacts: [],
  ichatSelectedUser: {},
  ichatChats: [],
  ichatPages: [],
  loading: false,
  userLoading: false,
  products: [],
  orderData: {},
  multiSelectedPage: {},
  originUserPages: [],

  allIchatContacts: [],
  allIchatPages: [],
  allIchatChats: [],
  allIchatSelectedUser: {},
  allSelectedPage: {},
  mainPage: {},
  allUrlNext: []
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true }
    case 'LOADED':
      return { ...state, loading: false }
    // case 'GET_USER_PROFILE':
    //   return { ...state, userProfile: action.userProfile }
    case 'GET_CHAT_CONTACTS':
      return { ...state, chats: action.data.chatsContacts, contacts: action.data.contacts }
    case 'SELECT_CHAT':
      return { ...state, selectedUser: action.data, ichatChats: [] }
    case 'SEND_MSG':
      // ** Add new msg to chat
      const newMsg = action.data.response.chat
      return { ...state, selectedUser: { ...state.selectedUser, chat: newMsg } }
    case 'GET_ALL_CHAT_CONTACTS':
      return { ...state, allIchatContacts: action.data }
    case 'GET_ALL_CHAT_CONTACTS_NEXT':
      return { ...state, allIchatContacts: [...state.allIchatContacts, ...action.data] }
    case 'ALL_SELECT_PAGE':
      return { ...state, allSelectedPage: action.data }
    case 'ICHAT_MULTI_SELECT_PAGE':
      return { ...state, multiSelectedPage: action.data }
    case 'ALL_SELECT_CHAT':
      const index = state.allIchatContacts.findIndex(it => it.facebook_id === action.data.facebook_id)
      const clone = state.allIchatContacts
      if (action.data.facebook_id === clone[index].facebook_id) {
        clone[index]['unseen'] = null
      }
      clone[index].profile = action.data.profile_pic
      return { ...state, allIchatSelectedUser: action.data.data_customer, allIchatChats: action.data.chat, alIchatContacts: clone }
    case 'CLEAR_ALL_SELECT_CHAT':
      return { ...state, allIchatSelectedUser: {}, allIchatChats: [] }
    case 'GET_ALL_PAGE':
      return { ...state, allIchatPages: action.data }
    case 'USER_LOADING':
      return { ...state, userLoading: true }
    case 'USER_LOADED':
      return { ...state, userLoading: false }
    case 'ALL_CHECK_CUSTOMER_FACEBOOK': //ต้อง GET Tags
      if (state.allSelectedPage.page_id === action.data.fb.page_id) {
        const newContact = {}
        const cloneContact = state.allIchatContacts
        const oldContactIndex = state.allIchatContacts.findIndex(it => it.facebook_id === action.data.fb.facebook_id)
        const oldContact = state.allIchatContacts.find(it => it.facebook_id === action.data.fb.facebook_id)
        let tags = []
        let unseen = null
        let url_next = ''
        if (oldContact && oldContact.tags) {
          tags = oldContact.tags
          url_next = oldContact.url_next
        }
        if (oldContact) {
          if (Object.keys(action.data.fb).length) {
            if (state.allIchatSelectedUser.facebook_id !== action.data.fb.facebook_id) {
              const findCountUnseen = action.data.fb.count_msg.find(it => it.facebook_id === oldContact.facebook_id)
              if (findCountUnseen) { unseen = findCountUnseen.count_msg }
            }

          }
        }

        newContact['date_update'] = action.data.fb.date_update
        newContact['facebook'] = action.data.fb.facebook
        newContact['facebook_id'] = action.data.fb.facebook_id
        newContact['message'] = action.data.fb.message
        newContact['profile'] = action.data.fb.profile
        newContact['tags'] = tags
        newContact['times'] = action.data.fb.times
        newContact['unseen'] = unseen
        newContact['url_next'] = url_next
        newContact['t_id'] = action.data.fb.t_id ? action.data.fb.t_id : null
        newContact['page_id'] = oldContact ? oldContact.page_id : action.data.fb.page_id
        newContact['page_name'] = oldContact ? oldContact.page_name : action.data.fb.page_name
        newContact['page_token'] = oldContact ? oldContact.page_token : action.data.fb.page_token
        newContact['page_logo'] = oldContact ? oldContact.page_logo : action.data.fb.page_logo

        if (oldContactIndex !== -1) {
          cloneContact.splice(oldContactIndex, 1)
        }
        cloneContact.unshift(newContact)
        return { ...state }
      } else {
        return { ...state }
      }
    // case 'SET_NEW_CHAT':
    //   return { ...state, ichatChats: action.data.clone }
    // case 'CLEAR_CHAT':
    //   return { ...state, allIchatChats: [] }
    case 'ALL_UPDATE_SELECT_CHAT_INDEX':
      const cloneChat = state.allIchatChats
      const i = action.data.baseIndex
      const slip_res = action.data.slip_result
      cloneChat.splice(i, 1, {
        type: cloneChat[i] && cloneChat[i].type ? cloneChat[i].type : '',
        time: cloneChat[i] && cloneChat[i].time ? cloneChat[i].time : '',
        message: 'img',
        img: action.data.img,
        loading: 0,
        date: cloneChat[i] && cloneChat[i].date ? cloneChat[i].date : null,
        slip_result: slip_res
      })
      return { ...state, allIchatChats: cloneChat }
    case 'ALL_UPDATE_SELECT_CHAT':
      const cloneChat1 = state.allIchatChats
      const new_message = action.data.newMessage
      return { ...state, allIchatChats: [...cloneChat1, new_message] }
    case 'GET_PRODUCTS':
      return { ...state, products: action.data }
    case 'INIT_ORDER_FORM_DATA':
      return { ...state, orderData: action.data }
    case 'ALL_CLEAR_CHAT_DATA':
      return { ...state, allSelectedPage: {}, allIchatContacts: [], allIchatSelectedUser: {}, allIchatChats: [], allUrlNext: [] }
    case 'ALL_CLEAR_SOME_CHAT_DATA':
      return { ...state, allIchatSelectedUser: {}, allIchatChats: [] }
    case 'ALL_CLEAR_CONTACT':
      return { ...state, allIchatContacts: [] }
    case 'ALL_UPDATE_SELECT_CHAT_TIME':
      const cloneChat2 = state.allIchatChats
      const idx = action.data.baseIndex
      cloneChat2[idx].time = moment().format('HH:mm')
      return { ...state, allIchatChats: cloneChat2 }
    case 'ALL_UPDATE_SELECT_CHAT_FAILED':
      const cloneChat3 = state.allIchatChats
      const baseIndex = action.data.baseIndex
      cloneChat3.splice(baseIndex, 1)
      return { ...state, allIchatChats: cloneChat3 }
    case 'ALL_CLEAR_SENDING_CHAT':
      const cloneChat4 = state.allIchatChats
      const selectIdx = action.data.selectIdx
      cloneChat4.splice(selectIdx, 1)
      return { ...state, allIchatChats: cloneChat4 }
    case 'ALL_UPDATE_SLIP_RESULT':
      const cloneChat5 = state.allIchatChats
      const result_index = action.data.baseIndex
      const slip_resp = action.data.slip_result
      cloneChat5[result_index]['slip_result'] = slip_resp
      return { ...state, allIchatChats: cloneChat5 }
    case 'UPDATE_ORIGIN_USER_PAGES':
      return { ...state, originUserPages: action.data }
    case 'ALL_SET_MAIN_PAGE':
      return { ...state, mainPage: action.data }
    case 'ALL_URL_NEXT':
      return { ...state, allUrlNext: action.data }
    case 'ALL_SET_TAGS_INDEX':
      state.allIchatContacts[action.data.index]['tags'] = action.data.tags
      return { ...state }
    case 'ALL_SET_NEW_CONTACT':
      if (Object.keys(action.data).length > 0) {
        const cloneContact = state.allIchatContacts
        const oldContactIndex = state.allIchatContacts.findIndex(it => it.facebook_id === action.data.facebook_id)
        const oldContact = state.allIchatContacts.find(it => it.facebook_id === action.data.facebook_id)
        if (oldContactIndex !== -1) {
          cloneContact.splice(oldContactIndex, 1)
        }
        cloneContact.unshift(action.data)
        return { ...state }
      } else {
        return { ...state }
      }
    default:
      return state
  }
}

export default chatReducer
