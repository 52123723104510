import moment from "moment"
const initialState = {
  chats: [],
  contacts: [],
  userProfile: {},
  selectedUser: {},
  selectedPage: {},
  ichatContacts: [],
  ichatSelectedUser: {},
  ichatChats: [],
  ichatPages: [],
  loading: false,
  userLoading: false,
  products: [],
  orderData: {},
  multiSelectedPage: {}
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true }
    case 'LOADED':
      return { ...state, loading: false }
    case 'GET_USER_PROFILE':
      return { ...state, userProfile: action.userProfile }
    case 'GET_CHAT_CONTACTS':
      return { ...state, chats: action.data.chatsContacts, contacts: action.data.contacts }
    case 'SELECT_CHAT':
      return { ...state, selectedUser: action.data, ichatChats: [] }
    case 'SEND_MSG':
      // ** Add new msg to chat
      const newMsg = action.data.response.chat
      return { ...state, selectedUser: { ...state.selectedUser, chat: newMsg } }
    case 'ICHAT_GET_CHAT_CONTACTS':
      return { ...state, ichatContacts: action.data }
    case 'ICHAT_GET_CHAT_CONTACTS_NEXT':
      return { ...state, ichatContacts: [...state.ichatContacts, ...action.data] }
    case 'ICHAT_SELECT_PAGE':
      return { ...state, selectedPage: action.data }
    case 'ICHAT_MULTI_SELECT_PAGE':
      return { ...state, multiSelectedPage: action.data }
    case 'ICHAT_SELECT_CHAT':
      const index = state.ichatContacts.findIndex(it => it.facebook_id === action.data.facebook_id)
      const clone = state.ichatContacts
      if (action.data.facebook_id === clone[index].facebook_id) {
        clone[index]['unseen'] = null
      }
      clone[index].profile = action.data.profile_pic
      return { ...state, ichatSelectedUser: action.data.data_customer, ichatChats: action.data.chat, ichatContacts: clone }
    case 'CLEAR_SELECT_CHAT':
      return { ...state, ichatSelectedUser: {}, ichatChats: [] }
    case 'ICHAT_GET_PAGE':
      return { ...state, ichatPages: action.data }
    case 'USER_LOADING':
      return { ...state, userLoading: true }
    case 'USER_LOADED':
      return { ...state, userLoading: false }
    case 'CHECK_CUSTOMER_FACEBOOK': //ต้อง GET Tags
      if (state.selectedPage.page_id === action.data.fb.page_id) {
        const newContact = {}
        const cloneContact = state.ichatContacts
        const oldContactIndex = state.ichatContacts.findIndex(it => it.facebook_id === action.data.fb.facebook_id)
        const oldContact = state.ichatContacts.find(it => it.facebook_id === action.data.fb.facebook_id)
        let tags = []
        let unseen = null
        let url_next = ''
        if (oldContact && oldContact.tags) {
          tags = oldContact.tags
          url_next = oldContact.url_next
        }
        if (oldContact) {
          if (Object.keys(action.data.fb).length) {
            if (state.ichatSelectedUser.facebook_id !== action.data.fb.facebook_id) {
              const findCountUnseen = action.data.fb.count_msg.find(it => it.facebook_id === oldContact.facebook_id)
              if (findCountUnseen) { unseen = findCountUnseen.count_msg }
            }

          }
        }

        newContact['date_update'] = action.data.fb.date_update
        newContact['facebook'] = action.data.fb.facebook
        newContact['facebook_id'] = action.data.fb.facebook_id
        newContact['message'] = action.data.fb.message
        newContact['profile'] = action.data.fb.profile
        newContact['tags'] = tags
        newContact['times'] = action.data.fb.times
        newContact['unseen'] = unseen
        newContact['url_next'] = url_next
        newContact['t_id'] = action.data.fb.t_id ? action.data.fb.t_id : null
        if (oldContactIndex !== -1) {
          cloneContact.splice(oldContactIndex, 1)
        }
        cloneContact.unshift(newContact)
        return { ...state }
      } else {
        return { ...state }
      }
    case 'SET_NEW_CHAT':
      return { ...state, ichatChats: action.data.clone }
    case 'CLEAR_CHAT':
      return { ...state, ichatChats: [] }
    case 'UPDATE_SELECT_CHAT_INDEX':
      const cloneChat = state.ichatChats
      const i = action.data.baseIndex
      const slip_res = action.data.slip_result
      cloneChat.splice(i, 1, {
        type: cloneChat[i] && cloneChat[i].type ? cloneChat[i].type : '',
        time: cloneChat[i] && cloneChat[i].time ? cloneChat[i].time : '',
        message: 'img',
        img: action.data.img,
        loading: 0,
        date: cloneChat[i] && cloneChat[i].date ? cloneChat[i].date : null,
        slip_result: slip_res
      })
      return { ...state, ichatChats: cloneChat }
    case 'UPDATE_SELECT_CHAT':
      const cloneChat1 = state.ichatChats
      const new_message = action.data.newMessage
      return { ...state, ichatChats: [...cloneChat1, new_message] }
    case 'GET_PRODUCTS':
      return { ...state, products: action.data }
    case 'INIT_ORDER_FORM_DATA':
      return { ...state, orderData: action.data }
    case 'CLEAR_ALL_CHAT_DATA':
      return { ...state, selectedPage: {}, ichatContacts: [], ichatPages: [], ichatSelectedUser: {}, ichatChats: [] }
    case 'CLEAR_CONTACT':
      return { ...state, ichatContacts: [] }
    case 'UPDATE_SELECT_CHAT_TIME':
      const cloneChat2 = state.ichatChats
      const idx = action.data.baseIndex
      cloneChat2[idx].time = moment().format('HH:mm')
      return { ...state, ichatChats: cloneChat2 }
    case 'UPDATE_SELECT_CHAT_FAILED':
      const cloneChat3 = state.ichatChats
      const baseIndex = action.data.baseIndex
      cloneChat3.splice(baseIndex, 1)
      return { ...state, ichatChats: cloneChat3 }
    case 'CLEAR_SENDING_CHAT':
      const cloneChat4 = state.ichatChats
      const selectIdx = action.data.selectIdx
      cloneChat4.splice(selectIdx, 1)
      console.log('cloneChat4', cloneChat4)
      return { ...state, ichatChats: cloneChat4 }
    case 'UPDATE_SLIP_RESULT':
      const cloneChat5 = state.ichatChats
      const result_index = action.data.baseIndex
      const slip_resp = action.data.slip_result
      cloneChat5[result_index]['slip_result'] = slip_resp
      return { ...state, ichatChats: cloneChat5 }
    default:
      return state
  }
}

export default chatReducer
