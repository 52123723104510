// **  Initial State
const initialState = {
  userData: {},
  token: null
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: action.data.user,
        token: action.data.api_token
        // [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        // [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }
    case 'LOGOUT':
      // const obj = { ...action }
      // delete obj.type
      return { ...state, userData: {}, token: null }
    default:
      return state
  }
}

export default authReducer
